@.closeModalHandler = ->
    clearCheckBoxAndSubmit = () ->
        $(document).find('.jsModal').removeClass('active') || $(document).find('.jsModalSlide').removeClass('active')

        submit = $(document).find('.jsSubmitButton')
        submit.attr('disabled', true)

        checkbox = $(document).find('.jsChceckboxRodo')
        checkbox.prop('checked', false)

    $(document).on('click', '.jsModalContentClose', (e) ->
        e.stopImmediatePropagation()
        $('body').css('overflow', '')
        $(this).parents('.modal-overlay').removeClass('active')
        inputs = $(this).parents('.jsModalFormTitle').siblings('.jsModalContentChange').find('input')
        inputs.each(() ->
            $(this).val('')
        )
        $(this).parents('.jsModalSlide').removeClass('active')
        if !($(this).parents('.jsModalCustomizerAddProject').length > 0)
            $('.jsModalCustomizerOverlay').removeClass('active')

        textareas = $(this).parents('.jsModalFormTitle').siblings('.jsModalContentChange').find('textarea')
        textareas.each(() ->
            $(this).val('')
        )

        # hidden graphics in modal slide
        $('.jsBasketQuoteImage').each(() ->
            $(this).find('img').css('visibility', 'hidden')
        )

        # close modal in pricing save
        $('.jsPricingSaveOverlay').removeClass('active')
        $('.jsLinkButton').css('border-radius', '6px')

        # clear careers modal attached files
        if ($(this).parent().siblings('.jsModalContentChange').find('#jobApplicationForm').length > 0)
            $('#jobApplicationForm').find('.remove').trigger('click')

        clearCheckBoxAndSubmit()
    )

    $(document).on('click', '.jsModalInner', (event) ->
        event.stopImmediatePropagation();

        if event.target == $(this)[0]
            inputs = $(this).find('input')
            inputs.each(() ->
                $(this).val('')
            )

            textareas = $(this).find('textarea')
            textareas.each(() ->
                $(this).val('')
            )

            # close modal in pricing save
            $('.jsPricingSaveOverlay').removeClass('active')
            $('.jsLinkButton').css('border-radius', '6px')

            clearCheckBoxAndSubmit()
    )

    # Modal Slide - product page - close
    $('.jsModalSlideResourcesClose').on('click', (e) ->
        modal = $(this).parents('.jsModalSlideResources')
        overlay = modal.siblings('.jsModalSlideResourcesOverlay')

        modal.removeClass('active')
        overlay.removeClass('active')
        $('body').css('overflow', '')

        # Clear query param
        deleteParameterByName('tab')
    )

    # Modal Slide - product page - overlay
    $('.jsModalSlideResourcesOverlay').on('click', (e) ->
        modal = $(this).siblings('.jsModalSlideResources')
        closeBtn = modal.find('.jsModalSlideResourcesClose')

        closeBtn.trigger('click')
    )

    # Modal Slide - customizer product page - overlay
    $(document).on('click', '.jsModalCustomizerOverlay', (e) ->
        modal = $(this).siblings('.jsModalSlideCustomizer')
        closeBtn = modal.find('.jsModalContentClose')

        closeBtn.trigger('click')
    )

    # Modal - fullscreen close btn
    $('.jsModalFullScreenClose').on('click', (e) ->
        modal = $(this).parents('.jsModalFullScreen')

        modal.removeClass('active')
    )

    # Modal - fullscreen overlay
    $('.jsModalFullScreenOverlay').on('click', (e) ->
        modal = $(this).parents('.jsModalFullScreen')
        closeBtn = modal.find('.jsModalFullScreenClose')

        closeBtn.trigger('click')
    )


